@font-face {
    font-family: 'MuseoSans';
    font-weight: 300;
    src: url('./fonts/MuseoSans_300.otf') format('opentype');
}

@font-face {
    font-family: 'MuseoSans';
    font-weight: 300;
    font-style: italic;
    src: url('./fonts/MuseoSans_300_Italic.otf') format('opentype');
}
