@font-face {
  font-family: 'EnzoOT';
  font-weight: 400;
  src: url('./fonts/EnzoOT-Medi.otf') format('opentype');
}

@font-face {
  font-family: 'EnzoOT';
  font-weight: 400;
  font-style: italic;
  src: url('./fonts/EnzoOT-MediIta.otf') format('opentype');
}

@font-face {
  font-family: 'EnzoOT';
  font-weight: 700;
  src: url('./fonts/EnzoOT-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'EnzoOT';
  font-weight: 700;
  font-style: italic;
  src: url('./fonts/EnzoOT-BoldIta.otf') format('opentype');
}

@font-face {
  font-family: 'EnzoOT';
  font-weight: 100;
  src: url('./fonts/EnzoOT-Light.otf') format('opentype');
}

@font-face {
  font-family: 'EnzoOT';
  font-weight: 100;
  font-style: italic;
  src: url('./fonts/EnzoOT-LightIta.otf') format('opentype');
}
